import { DeleteSVG, PlusSVG, SearchSVG } from "assets/svg/components";
import { TagButton } from "components/Buttons";
import { useState } from "react";
import {
  ButtonsWrapper,
  Container,
  TitleDescriptionContainer,
  FlexContent,
} from "../style";
import { itemTitleDescriptionProps } from "../types";
import { Pen } from "assets/svg/components/Pen";
import { InputLabel } from "components/Inputs/styles";
import { IconButton } from "pages/Clients/List/Table/styles";
import { SwitchWrapper } from "pages/Travel/Register/components/Tips/style";
import Switch from "components/Inputs/Switch";

const TitleDescriptionList = ({
  description,
  handleRemoveListItem,
  title,
  itemId,
  handleEdit,
  isVisible = false,
}: itemTitleDescriptionProps) => {
  const [showDesc, setShowDesc] = useState(false);

  const editItem = () => {
    if (handleEdit) {
      handleEdit(itemId);
    }
  };

  return (
    <>
      {description && (
        <TitleDescriptionContainer
          initial={{ opacity: 0, scale: 0.9, x: "100" }}
          animate={{ opacity: 1, scale: 1, x: 0 }}
          transition={{ duration: 0.3 }}
        >
          <FlexContent>
            <InputLabel>{title}</InputLabel>
            <ButtonsWrapper
              initial={{ opacity: 0, scale: 0.9, x: "100" }}
              animate={{ opacity: 1, scale: 1, x: 0 }}
              transition={{ duration: 0.5 }}
            >
              <SwitchWrapper>
                <Switch
                  checked={isVisible}
                  handleChange={() => {}}
                  options={["Invisível", "Visível"]}
                  disabled
                />
              </SwitchWrapper>

              <IconButton onClick={() => handleRemoveListItem(itemId)}>
                <DeleteSVG />
                <p>Deletar</p>
              </IconButton>

              <IconButton
                onClick={(event) => {
                  event.preventDefault();
                  editItem();
                }}
              >
                <Pen />
                <p>Editar</p>
              </IconButton>
            </ButtonsWrapper>
          </FlexContent>

          <IconButton
            onClick={(event) => {
              event.preventDefault();
              setShowDesc((old) => !old);
            }}
          >
            {showDesc ? "Esconder Descrição" : "Mostrar Descrição"}
          </IconButton>
          {showDesc && (
            <span dangerouslySetInnerHTML={{ __html: description }}></span>
          )}
        </TitleDescriptionContainer>
      )}
    </>
  );
};
export default TitleDescriptionList;
