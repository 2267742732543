import React, { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Container,
  IconButton,
  Header,
  PageSizeSelector,
  SeachField,
  SearchContainer,
  TableContainer,
  ButtonWrapper,
  TableButtons,
} from "./styles";

import { DeleteSVG } from "../../../../assets/svg/components/DeleteSVG";
import { SearchSVG } from "assets/svg/components/SearchSVG";
import { useMutation, useQuery } from "react-query";
import { search as getClientsList } from "../../../../api/requests/user/search";
import { ReceiveUser } from "types/data/User";
import { ILimit } from "pages/Travel/Register/types";
import remove from "api/requests/user/remove";
import { queryClient } from "api/queryClient";
import { Button } from "components/Buttons";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PlusSVG } from "assets/svg/components";
import { useSelectedIdContext } from "context/SelectedIdContext";
import ConfirmModal from "components/ConfirmModal";
import { clientColumns } from "./Schemas";
import { Stack } from "@mui/material";
import { InfoTexts, PerfilInfos } from "pages/Travel/Table/style";

export default function DataTable() {
  const [data, setData] = React.useState<ReceiveUser[]>([]);
  const [search, setSearch] = React.useState("");
  const [selectedClients, setSelectedClients] = React.useState<React.Key[]>([]);
  const [selectedClientId, setSelectedClientId] = React.useState<React.Key>();

  const [showModal, setShowModal] = React.useState(false);
  const [limit, setLimit] = React.useState<ILimit>(10);

  const {
    data: queryData,
    isLoading,
    isFetched,
  } = useQuery<ReceiveUser[]>("clients-list", () => getClientsList(), {
    retry: 1,
  });
  const navigate = useNavigate();

  const { mutate } = useMutation({
    mutationFn: (id: number) => {
      return remove(id).then((response) => response);
    },
    onSuccess: () => {
      setShowModal(false);
      queryClient.invalidateQueries("clients-list", { exact: true });
      toast.success("cliente deletado");
    },
  });
  const handleNavigate = (path: string) => {
    navigate(path);
  };
  const handleDeleteRows = () => {
    const filteredRows =
      queryData?.filter((queryData) =>
        selectedClients.includes(queryData.id)
      ) || [];
    if (filteredRows.length > 1) {
      toast.warning("Selecione apenas um item");
    } else {
      const idToDelete = filteredRows[0].id;

      mutate(idToDelete);
    }
  };

  const { setSelectedId } = useSelectedIdContext();

  useEffect(() => {
    const queryDataFormated = queryData?.map((item) => {
      const formatData: ReceiveUser = {
        ...item,
      };
      return { ...formatData };
    });
    setData(queryDataFormated || []);
  }, [isFetched, queryData, mutate]);

  const handleClientButtonClicked = () => {
    setSelectedId(selectedClientId);
    navigate("/clientes/cliente");
  };

  const handleCancelModal = () => {
    setShowModal(false);
  };
  const OpenDataButton = () => {
    if (selectedClientId?.valueOf && selectedClients.length === 1) {
      return (
        <Button onClick={handleClientButtonClicked} color="orange">
          Abrir
        </Button>
      );
    } else {
      return <span></span>;
    }
  };

  return (
    <Container>
      <PerfilInfos>
        <InfoTexts>
          <h1>Lista de Clientes</h1>
        </InfoTexts>
      </PerfilInfos>
      <Header>
        <SearchContainer>
          <SearchSVG />
          <SeachField
            type="text"
            placeholder="Pesquisar"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </SearchContainer>
        <TableButtons>
          {selectedClientId?.valueOf && selectedClients.length === 1 && (
            <IconButton
              type="button"
              onClick={() => setShowModal((old) => !old)}
            >
              <DeleteSVG />
              <p>Deletar</p>
            </IconButton>
          )}

          <ButtonWrapper>
            <button
              color="green"
              onClick={() => handleNavigate("/clientes/cadastrar")}
            >
              <PlusSVG />
              <span>Novo</span>
            </button>
          </ButtonWrapper>
          <ButtonWrapper buttonColor="#C74C39">
            <OpenDataButton />
          </ButtonWrapper>
        </TableButtons>
      </Header>

      <TableContainer>
        <DataGrid
          loading={isLoading}
          components={{
            NoResultsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                Nada para ver aqui
              </Stack>
            ),
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                Nada para ver aqui
              </Stack>
            ),
          }}
          onCellDoubleClick={(params, event) => {
            if (!event.ctrlKey) {
              setSelectedId(params.row.id);
              navigate("/clientes/cliente");
              event.defaultMuiPrevented = true;
            }
          }}
          style={{
            paddingTop: 5,
            paddingLeft: 5,
            borderRadius: 10,
            border: "none",
            height: "100%",
            outline: "none",
            fontFamily: "Poppins",
            color: "#646464",
          }}
          rows={
            search
              ? data.filter((item) =>
                  item.fullname.toLowerCase().includes(search.toLowerCase())
                )
              : data
          }
          checkboxSelection
          pageSize={limit}
          rowsPerPageOptions={[5]}
          columns={clientColumns}
          onSelectionModelChange={(newSelection) => {
            setSelectedClientId(newSelection[0]);
            setSelectedClients(newSelection.map((item) => item));
          }}
        />
      </TableContainer>

      <PageSizeSelector>
        <label htmlFor="page">Visualizar</label>
        <select
          name="page"
          id="page"
          onChange={(e) => setLimit(Number(e.target.value) as ILimit)}
        >
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="25">25</option>
        </select>
      </PageSizeSelector>
      <ConfirmModal
        open={showModal}
        handleConfirm={handleDeleteRows}
        handleCancel={handleCancelModal}
        message="Deseja mesmo deletar esse cliente?"
      />
    </Container>
  );
}
