import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from "react";

interface IProvider {
  children: ReactNode;
}

export const NEW_ITEM = 0;

export interface ISelectedIdContext {
  selectedId: React.Key | undefined;
  setSelectedId: React.Dispatch<React.SetStateAction<React.Key | undefined>>;
}

const LOCAL_STORAGE_KEY = "selectedId";

const SelectedIdContext = createContext<ISelectedIdContext | null>(null);

export const SelectedIdContextProvider = ({ children }: IProvider) => {
  const [selectedId, setSelectedId] = useState<React.Key | undefined>(() => {
    // Inicializa com o valor armazenado no localStorage, se existir
    const savedId = localStorage.getItem(LOCAL_STORAGE_KEY);
    return savedId ? JSON.parse(savedId) : undefined;
  });

  useEffect(() => {
    // Atualiza o localStorage sempre que o estado mudar
    if (selectedId !== undefined) {
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(selectedId));
    } else {
      localStorage.removeItem(LOCAL_STORAGE_KEY); // Remove se estiver indefinido
    }
  }, [selectedId]);

  const value: ISelectedIdContext = {
    selectedId,
    setSelectedId,
  };

  return (
    <SelectedIdContext.Provider value={value}>
      {children}
    </SelectedIdContext.Provider>
  );
};

export const useSelectedIdContext = () => {
  const context = useContext(SelectedIdContext);

  if (!context) {
    throw new Error(
      "useSelectedIdContext must be used within a SelectedIdContextProvider"
    );
  }

  return context;
};
