export const removeEmptyStringAttributes = (obj: {
  [key: string]: any;
}): any => {
  for (const key in obj) {
    if (
      (typeof obj[key] === "string" && obj[key].trim() === "") ||
      obj[key] === null ||
      obj[key] === "undefined"
    ) {
      delete obj[key];
    }
  }
  return obj;
};
