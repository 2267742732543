import { DeleteSVG } from "assets/svg/components";
import { TagButton } from "components/Buttons";
import { useState } from "react";
import { FormListItemProps } from "../types";
import { ButtonsWrapper, Container, Content, HideContent } from "./style";
const FormListItem = ({
  description,
  handleRemoveListItem,
  title,
  itemId,
  adress,
  coast,
  comment,
  date,
  option,
  order,
  time,
}: FormListItemProps) => {
  const [openItem, setOpenItem] = useState(false);
  return (
    <>
      <Container
        onHoverStart={() => setOpenItem(true)}
        onHoverEnd={() => setOpenItem(false)}
        whileHover={{ height: "150px" }}
        initial={{ opacity: 0, scale: 0.9, x: "100" }}
        animate={{ opacity: 1, scale: 1, x: 0 }}
        transition={{ duration: 0.3 }}
      >
        <Content>
          <h2>{title}</h2>
          <span>{description}</span>
          <ButtonsWrapper
            initial={{ opacity: 0, scale: 0.9, x: "100" }}
            animate={{ opacity: 1, scale: 1, x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <TagButton
              bgColor="red"
              onClick={() => handleRemoveListItem(itemId)}
            >
              <DeleteSVG />
            </TagButton>
          </ButtonsWrapper>
        </Content>
        {openItem && (
          <>
            <HideContent>
              <span>{adress}</span>
              <span>{coast}</span>
              <span>{comment}</span>
              <span>{date}</span>
            </HideContent>
            <HideContent>
              <span>{option}</span>
              <span>{order}</span>
              <span>{time}</span>
            </HideContent>
          </>
        )}
      </Container>
    </>
  );
};
export default FormListItem;
