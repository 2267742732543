import { DataGrid } from "@mui/x-data-grid";
import { Button } from "components/Buttons";
import { Input } from "components/Inputs";
import { MaskedInput } from "components/Inputs/Masks";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  DateInputs,
  DoubleItemsWrapper,
  TableContainer,
  InputLabel,
  DateContent,
  AddButtonWrapper,
} from "../../style";
import {
  ActivitiesSection,
  EditButton,
  RatingButton,
  SectionTitle,
  ErrorAndInputContainer,
} from "./style";
import { ILimit } from "../../types";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { search } from "api/requests/activity/search";
import { update as updateTravelActivity } from "api/requests/travelActivity/update";
import { useMutation, useQueries } from "react-query";
import { activities, activitiesReceive } from "types/data/Activities";
import {
  currencyToNumber,
  formatDateToShow,
  formatedDateOfRequest,
  formatedDateToShow,
  numberToCurrency,
} from "utils/formatters";
import { create } from "api/requests/activity/create";
import { queryClient } from "api/queryClient";
import { travel_activities } from "types/data/TravelActivities";
import { create as createTravelActivity } from "api/requests/travelActivity/create";
import { search as searchTravelActivities } from "api/requests/travelActivity/search";
import { ActivitiesInputForms } from "./types";
import { activitiesCollum, schema } from "./schemas";
import { remove } from "api/requests/travelActivity/remove";
import { Props } from "./types";
import Switch from "components/Inputs/Switch";
import Rating from "./components/Rating";
import { DeleteWrapper } from "pages/Travel/Table/style";
import { DeleteSVG } from "assets/svg/components";
import ConfirmModal from "components/ConfirmModal";
import { update as updateActivity } from "api/requests/activity/update";
import { Pen } from "assets/svg/components/Pen";

import Section from "components/Section/Section";
import { Stack, TextField, Autocomplete } from "@mui/material";
import { removeEmptyStringAttributes } from "utils/removeEmptyStringAttributes";
import { toast } from "react-toastify";

const defaultOptions = [""];

export default function ActivitiesForm({ travelId, disable }: Props) {
  const [itemSelected, setItemSelected] = useState<activities>();
  const [selectedId, setSelectedId] = React.useState<number>();
  const [getActivityId, setGetActivityId] = useState<number | undefined>();

  const [ratingModal, setRatingModal] = useState(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [showModal, setShowModal] = React.useState(false);
  const [cleanFields, setCleanFields] = useState(false);
  const [options, setOptions] = useState<string[]>(defaultOptions);
  const [isEditingActivity, setIsEditingActivity] = useState(false);
  const [budget, setBudget] = useState<string>();
  const [startTime, setStartTime] = useState<string>();
  const [endTime, setEndTime] = useState<string>();
  const [itemSelectedTitle, setItemSelectedTitle] = useState<
    string | null | undefined
  >("");
  const [activityList, setActivitesList] = useState<
    Partial<activitiesReceive>[]
  >([]);

  const [selectedActivities, setSelectedActivities] = React.useState<
    React.Key[]
  >([]);
  const [editorValue, setEditorValue] = useState("");

  const {
    register,
    getValues,
    handleSubmit,
    setValue,
    trigger,
    reset,
    formState,
    watch,
    formState: { errors, isSubmitSuccessful },
  } = useForm<ActivitiesInputForms>({
    resolver: yupResolver(schema),
  });

  const handleFormatData = () => {
    const dataToTable: Partial<activitiesReceive>[] = [];

    travelActivitiesQuery.data?.map((item) => {
      const formatedData = formatedDateToShow(item.activitydate);
      const newTableData: Partial<activitiesReceive> = {
        id: item.id,
        activityId: item.activities.id,
        activity_type: item.activities.activity_type,
        endsat: item.activities.endsat,
        startsat: item.activities.startsat,
        title: item.activities.title,
        budget: numberToCurrency(item.activities.budget),
        activitydate: formatedDateToShow(item.activitydate),
        activityDay: formatedData.slice(0, 2),
        activityMonth: formatedData.slice(3, 5),
        activityYear: formatedData.slice(6, 11),
        ordenation: item.ordenation,
        isvisible: item.isvisible,
        description: item.description,
      };
      dataToTable.push(newTableData);
    });
    setActivitesList(dataToTable);
  };
  const handleDeleteActivitiesRows = () => {
    const filteredRows = activityList?.filter(
      (row) => !selectedActivities.includes(row.id!)
    );

    if (selectedId) {
      const idToString = selectedId.toString();
      const idToFormatToDelete = parseInt(idToString);
      deleteTravelActivity.mutate(idToFormatToDelete);
    }

    setActivitesList(filteredRows);
    setSelectedActivities([]);
  };
  const [activitiesQuery, travelActivitiesQuery, allTravelActivities] =
    useQueries([
      {
        queryKey: ["activities"],
        queryFn: () => search(),
        onSuccess: () => handleFormatData(),
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ["travel-activities"],
        queryFn: () => searchTravelActivities({ travel_id: travelId }),
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ["all-travel-activities"],
        queryFn: () => searchTravelActivities(),
        refetchOnWindowFocus: false,
      },
    ]);

  const handleCleanFields = () => {
    setValue("activityAddress", "");
    setValue("activityBudget", "");
    setValue("activityID", undefined);
    setValue("activityDay", "");
    setValue("activityMonth", "");
    setValue("activityYear", "");
    setValue("activityComment", "");
    setValue("activityOrder", "");
    setValue("activityTitle", "");
    setValue("activityType", "");
    setValue("startsat", "");
    setValue("endsat", "");
    setIsVisible(false);
    setEditorValue("");
    setItemSelectedTitle("");
    setCleanFields(true);
  };

  const getFormValues = () => {
    const activityTitle = getValues("activityTitle");
    const activityType = getValues("activityType");
    const activityDescription = editorValue;
    const activityAddress = getValues("activityAddress");
    const activityComment = getValues("activityComment");
    const startSat = getValues("startsat");
    const endSat = getValues("endsat");
    const activityOrder = getValues("activityOrder");
    const expectedCoast = getValues("activityBudget");
    const activityDay = getValues("activityDay");
    const activityMonth = getValues("activityMonth");
    const activityYear = getValues("activityYear");
    const activityID = getValues("activityID");
    const activityDate = getValues("activitydate");

    const activityFullDate = `${
      activityYear || String(activityDate).slice(6, 11)
    }-${activityMonth || String(activityDate).slice(3, 5)}-${
      activityDay || String(activityDate).slice(0, 2)
    }`;
    const budgetFormat = currencyToNumber(expectedCoast);

    const activityData: activities = {
      title: activityTitle || itemSelected?.title,
      activity_type: activityType,
      description: activityComment || activityDescription,
      address: activityAddress,
      budget: budgetFormat,
      endsat: endSat,
      startsat: startSat,
      activitydate: activityFullDate,
      id: activityID,
      activityDay,
      activityMonth,
      activityYear,
    };

    const travelActivityData: Partial<travel_activities> = {
      activity_id: activityID,
      travel_id: travelId,
      description: activityDescription,
      activitydate: activityFullDate,
      ordenation: activityOrder,
      isvisible: isVisible,
      id: selectedId,
    };
    return { activityData, travelActivityData };
  };

  const createActivity = useMutation({
    mutationFn: (item: activities) => create(item),

    onSuccess: (data) => {
      setGetActivityId(data.id);
      const { travelActivityData } = getFormValues();

      if (travelActivityData.id) {
        updateTravelActivityMutation.mutateAsync(
          removeEmptyStringAttributes(travelActivityData)
        );
      } else {
        createTravelActivityMutation.mutateAsync(
          removeEmptyStringAttributes({
            ...travelActivityData,
            activity_id: data.id,
          })
        );
      }
      handleCleanFields();
      queryClient.invalidateQueries("travel-activities");
    },
    onError: (error) => {
      handleCleanFields();
      toast.error(
        `Algo deu errado e não foi possível criar a atividade: ${error}`
      );
    },
  });

  const createTravelActivityMutation = useMutation({
    mutationFn: (item: Partial<travel_activities>) =>
      createTravelActivity(item),

    onSuccess: (data) => {
      setIsEditingActivity(false);
      queryClient.invalidateQueries("travel-activities");
      toast.success(`A atividade foi vinculada à viagem!`);
      handleCleanFields();
    },
    onError: (error) => {
      handleCleanFields();
      toast.error(`Algo deu errado: ${error}`);
    },
  });

  const updateTravelActivityMutation = useMutation({
    mutationFn: (item: Partial<travel_activities>) =>
      updateTravelActivity(item),

    onSuccess: (data) => {
      setIsEditingActivity(false);
      queryClient.invalidateQueries("travel-activities");
      toast.success(`A atividade foi vinculada à viagem!`);
      handleCleanFields();
    },
    onError: (error) => {
      handleCleanFields();
      toast.error(`Algo deu errado: ${error}`);
    },
  });

  const updateActivityMutation = useMutation({
    mutationFn: (data: Partial<activities>) => updateActivity(data),
    onSuccess: (data) => {
      setIsEditingActivity(false);
      const { travelActivityData } = getFormValues();

      if (travelActivityData.id) {
        updateTravelActivityMutation.mutateAsync(
          removeEmptyStringAttributes(travelActivityData)
        );
      } else {
        createTravelActivityMutation.mutateAsync(
          removeEmptyStringAttributes({
            ...travelActivityData,
            activity_id: data.id,
          })
        );
      }
      handleCleanFields();
      queryClient.invalidateQueries("travel-activities");
      toast.success(`A atividade foi atualizada`);
    },
    onError: (error) => {
      handleCleanFields();
      toast.error(
        `Algo deu errado e não foi possível atualizar a atividade: ${error}`
      );
    },
  });

  const deleteTravelActivity = useMutation({
    mutationFn: (id: number | undefined) => remove(id),

    onSuccess: (data) => {
      queryClient.invalidateQueries("travel-activities");
      setShowModal(false);
      toast.success(`Atividade deletada`);
    },
    onError: (error) => {
      toast.error(
        `Algo deu errado e não foi possível deletar a atividade: ${error}`
      );
    },
  });

  const handleFormSubmited = async () => {
    // TODO: aqui é o submit
    const { activityData } = getFormValues();
    const formatActivityData = {
      ...activityData,
      travel_id: travelId,
      activity_id: activityData.id,
      isvisible: isVisible,
      title: itemSelectedTitle || activityData.title,
      ordenation: activityData.ordenation,
    };

    if (formatActivityData.id) {
      updateActivityMutation.mutateAsync(
        removeEmptyStringAttributes(formatActivityData)
      );
    } else {
      createActivity.mutateAsync(
        removeEmptyStringAttributes(formatActivityData)
      );
    }
    setSelectedId(undefined);
    setItemSelectedTitle("");
  };

  useEffect(() => {
    handleFormatData();
  }, [travelActivitiesQuery.data]);

  const handleSetFieldsInitialValues = () => {
    if (itemSelected) {
      const travelActivitiesFiltered = allTravelActivities.data?.filter(
        (item) => item.activity_id === itemSelected.id
      );
      const lastTravelActivities = travelActivitiesFiltered?.pop();
      const newBudget = numberToCurrency(itemSelected.budget);
      setValue("activityTitle", itemSelected.title);
      setValue("activityAddress", itemSelected.address);
      setValue("activityBudget", newBudget);
      setValue("activityType", itemSelected.activity_type);
      setValue("activitydate", itemSelected.activitydate);
      setValue("activityOrder", itemSelected.ordenation);
      setEditorValue(lastTravelActivities?.description || "");
      setValue("activityDescription", lastTravelActivities?.description || "");
      setValue("activityID", itemSelected.id);
      setValue("endsat", itemSelected.endsat);
      setValue("startsat", itemSelected.startsat);
    }
  };
  const autocompleteChange = (
    event: SyntheticEvent<Element, Event>,
    value: string | null | undefined
  ) => {
    if (activitiesQuery.data) {
      const item = activitiesQuery.data.filter((item) => item.title === value);
    }
  };
  const handleSelect = (item: SyntheticEvent<HTMLDivElement, Event>) => {
    handleSetFieldsInitialValues();
  };
  const isOptionsData = () => {
    if (activitiesQuery.data) {
      activitiesQuery.data.map((item) => {
        if (item.title) {
          const newArray = [...options];
          const haveItemInArray = newArray.indexOf(item.title);
          if (haveItemInArray > -1) {
          } else {
            newArray.push(item.title);
            setOptions(newArray);
          }
        } else {
          return "";
        }
      });
    } else {
      return defaultOptions;
    }
  };

  useEffect(() => {
    isOptionsData();
  }, []);

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleClickActivity = () => {
    const filteredData = activityList.find((item) => item.id === selectedId);
    setBudget(String(filteredData?.budget || ""));
    setEndTime(filteredData?.endsat || "");
    setStartTime(filteredData?.startsat || "");
    setValue("activityBudget", String(filteredData?.budget || ""));
    setValue("activityAddress", filteredData?.address || "");
    setValue("startsat", filteredData?.startsat || "");
    setValue("endsat", filteredData?.endsat || "");
    setValue("activityType", filteredData?.activity_type || "");
    setValue("activityTitle", filteredData?.title);
    setValue("activityDescription", filteredData?.description);
    setValue("activityID", filteredData?.activityId);
    const activitydate = filteredData?.activitydate as string;
    setValue("activitydate", activitydate);
    setValue("activityDay", activitydate.slice(0, 2));
    setValue("activityMonth", activitydate.slice(3, 5));
    setValue("activityYear", activitydate.slice(6, 11));
    setEditorValue(filteredData?.description || "");
    setValue("activityOrder", filteredData?.ordenation);
    setIsVisible(filteredData?.isvisible || false);
    setItemSelectedTitle(filteredData?.title);
    setIsEditingActivity(true);
  };

  return (
    <ActivitiesSection>
      <SectionTitle>Atividades:</SectionTitle>
      <Section>
        <ErrorAndInputContainer>
          <InputLabel>Título da atividade: </InputLabel>
          <Autocomplete
            style={{
              width: "650px",
            }}
            freeSolo
            options={options}
            renderInput={(params) => (
              <TextField
                value={itemSelectedTitle}
                onChange={(e) => setItemSelectedTitle(e.target.value)}
                label={itemSelectedTitle}
                style={{ backgroundColor: "white" }}
                {...params}
              />
            )}
            onSelect={handleSelect}
            onChange={autocompleteChange}
            onInputChange={(event, newInputValue) => {
              autocompleteChange(event, newInputValue);
            }}
          />
        </ErrorAndInputContainer>

        <ErrorAndInputContainer style={{ marginRight: "300px" }}>
          <InputLabel>Visibilidade: </InputLabel>
          <Switch
            checked={isVisible}
            handleChange={() => setIsVisible(!isVisible)}
            options={["Invisível", "Visível"]}
          />
        </ErrorAndInputContainer>
      </Section>
      <Section>
        <ErrorAndInputContainer>
          <Input
            error={{
              message: errors.activityType?.message,
              showMessage: true,
            }}
            selectOptions={["opção 1", "opção 2"]}
            placeholder="Tipo de atividade"
            label="Tipo de atividade"
            size="500px"
            setValue={() =>
              setValue(
                "activityType",
                getFormValues().activityData.activity_type
              )
            }
            register={{ ...register("activityType") }}
            color={disable ? "" : "white"}
            disable={disable}
          />
        </ErrorAndInputContainer>

        <DateContent>
          <InputLabel>Data da atividade: </InputLabel>
          <DateInputs>
            <MaskedInput
              setValue={() =>
                setValue(
                  "activityDay",
                  getFormValues().activityData.activityDay
                )
              }
              register={{ ...register("activityDay", { required: true }) }}
              getValue={getValues}
              option="day"
              name="activityDay"
              id="activityDay"
              color="white"
              placeholder="DD"
              size="50px"
              radius={100}
            />

            <MaskedInput
              setValue={() =>
                setValue(
                  "activityMonth",
                  getFormValues().activityData.activityMonth
                )
              }
              register={{ ...register("activityMonth", { required: true }) }}
              getValue={getValues}
              option="month"
              id="activityMonth"
              color="white"
              placeholder="MM"
              size="50px"
              radius={100}
            />

            <MaskedInput
              getValue={getValues}
              setValue={() =>
                setValue(
                  "activityYear",
                  getFormValues().activityData.activityYear
                )
              }
              register={{ ...register("activityYear", { required: true }) }}
              option="year"
              id="activityYear"
              name="activityYear"
              color="white"
              placeholder="AAAA"
              size="120px"
              radius={100}
            />
          </DateInputs>
        </DateContent>
      </Section>
      <Section>
        <ErrorAndInputContainer>
          <InputLabel>Custo: </InputLabel>
          <MaskedInput
            getValue={getValues}
            setValue={() =>
              setValue(
                "activityBudget",
                numberToCurrency(getFormValues().activityData.budget)
              )
            }
            register={{ ...register("activityBudget") }}
            id="activityBudget"
            option="money"
            placeholder="Custo previsto"
            size="245px"
            color={disable ? "" : "white"}
            disable={disable}
          />
        </ErrorAndInputContainer>

        <ErrorAndInputContainer>
          <InputLabel>Horário de início: </InputLabel>
          <MaskedInput
            defaultValue={startTime}
            setValue={() =>
              setValue("startsat", getFormValues().activityData.startsat)
            }
            getValue={getValues}
            register={{ ...register("startsat") }}
            option="hour"
            placeholder="Horário de início"
            id="startsat"
            color={disable ? "" : "white"}
            disable={disable}
          />
        </ErrorAndInputContainer>
        <ErrorAndInputContainer>
          <InputLabel>Horário de término: </InputLabel>
          <MaskedInput
            defaultValue={endTime}
            setValue={() =>
              setValue("endsat", getFormValues().activityData.endsat)
            }
            getValue={getValues}
            register={{ ...register("endsat") }}
            option="hour"
            placeholder="Horário de término"
            id="endsat"
            color={disable ? "" : "white"}
            disable={disable}
          />
        </ErrorAndInputContainer>
      </Section>
      <Section>
        <ErrorAndInputContainer>
          <Input
            error={{
              message: errors.activityOrder?.message,
              showMessage: true,
            }}
            setValue={() =>
              setValue("activityOrder", getFormValues().activityData.ordenation)
            }
            getValues={getValues}
            register={{ ...register("activityOrder", { required: true }) }}
            inputType="select"
            placeholder="Ordem"
            color={disable ? "" : "white"}
            disable={disable}
            label="Ordem de exibição"
          />
        </ErrorAndInputContainer>
      </Section>

      <ErrorAndInputContainer>
        <InputLabel>Descrição da atividade: </InputLabel>
        <ReactQuill
          theme="snow"
          onChange={setEditorValue}
          value={editorValue}
        />
      </ErrorAndInputContainer>

      <AddButtonWrapper onClick={handleFormSubmited}>
        <Button>Salvar Atividade</Button>
      </AddButtonWrapper>

      {selectedActivities.length === 1 && selectedId && (
        <DoubleItemsWrapper>
          {selectedId && (
            <RatingButton onClick={() => setRatingModal(true)}>
              <Button color="green">Ver Avaliações</Button>
            </RatingButton>
          )}

          <EditButton onClick={handleClickActivity}>
            <Pen />
            <span>Editar</span>
          </EditButton>

          <DeleteWrapper
            type="button"
            onClick={() => setShowModal((old) => !old)}
          >
            <DeleteSVG />
            <span>Deletar</span>
          </DeleteWrapper>
        </DoubleItemsWrapper>
      )}
      <TableContainer>
        <DataGrid
          style={{
            paddingTop: 5,
            paddingLeft: 5,
            borderRadius: 10,
            border: "none",
            height: "750px",
            outline: "none",
          }}
          loading={travelActivitiesQuery.isLoading}
          rows={activityList}
          checkboxSelection
          rowsPerPageOptions={[5]}
          columns={activitiesCollum}
          onSelectionModelChange={(newSelection) => {
            const reactKeytoString = newSelection[0]?.toString();
            setSelectedId(parseInt(reactKeytoString));
            setSelectedActivities(newSelection);
          }}
          components={{
            NoResultsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                Nada para ver aqui
              </Stack>
            ),
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                Nada para ver aqui
              </Stack>
            ),
          }}
        />
      </TableContainer>
      <ConfirmModal
        handleCancel={handleCancel}
        handleConfirm={handleDeleteActivitiesRows}
        open={showModal}
        message="Deseja mesmo deletar essa atividade"
      />
      {ratingModal && (
        <Rating
          modalIsOn={ratingModal}
          activityId={selectedId}
          setCloseModal={setRatingModal}
        />
      )}
    </ActivitiesSection>
  );
}
