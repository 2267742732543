import React, { useState, useEffect } from "react";
import { Button } from "components/Buttons";
import Input from "components/Inputs/Normal";
import { yupResolver } from "@hookform/resolvers/yup";
import Checklist from "./components/Checklist";
import { search as getClientsList } from "../../../api/requests/user/search";
import {
  Container,
  FormContent,
  DateContent,
  InputLabel,
  SwitchWrapper,
  BudgetWrapper,
  TableContainer,
  SaveButtonWrapper,
  DoubleItemsWrapper,
  ErrorAndInputContainer,
} from "./style";
import { DataGrid } from "@mui/x-data-grid";
import { MaskedInput } from "components/Inputs/Masks";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  currencyToNumber,
  formatedDateToShow,
  numberToCurrency,
  numberToPhone,
} from "utils/formatters";
import { ILimit, InputsForm } from "./types";
import ActivitiesForm from "./components/ActivitiesForm";
import Tips from "./components/Tips";
import { useMutation, useQueries } from "react-query";
import { Travel, TravelReceive } from "types/data/Travel";
import { create as createTravel } from "api/requests/travel/create";
import { ReceiveUser } from "types/data/User";
import { queryClient } from "api/queryClient";
import { toast } from "react-toastify";
import { listUserDocs } from "../../../api/requests/Documents/list";
import SwitchInput from "components/Inputs/Switch";
import Images from "./Images";
import { Divider, Stack } from "@mui/material";
import { clientColumns } from "pages/Clients/List/Table/Schemas";
import { NEW_ITEM, useSelectedIdContext } from "context/SelectedIdContext";
import { search } from "api/requests/travel/search";
import { ITravelSelected } from "../TravelSelected/types";
import update from "api/requests/travel/update";
import updateTravelUsers from "api/requests/travel/updateTravelUsers";
import { removeEmptyStringAttributes } from "utils/removeEmptyStringAttributes";

const limit: ILimit = 10;

export default function TravelRegister() {
  const { selectedId, setSelectedId } = useSelectedIdContext();

  const [getTravelData, setGetTravelData] = useState<Travel & ITravelSelected>(
    {}
  );
  const [travelId, setTravelId] = useState<number>(Number(selectedId));
  const [isVisible, setIsVisible] = React.useState<boolean>(
    getTravelData.isvisible || false
  );
  const [listOfUsers, setListOfUsers] = React.useState<ReceiveUser[]>([]);
  const [usersToAdd, setUsersToAdd] = useState<Array<any>>([]);
  const [selectionModel, setSelectionModel] = useState<any>([]);
  const [travels, setTravels] = useState<Travel[]>([]);
  const [usersHasToUpdate, toggleUsersHasToUpdate] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<InputsForm>({
    defaultValues: {
      title: getTravelData.title,
      description: getTravelData.description,
      budget: getTravelData.budget,
      destination: getTravelData.destination,
    },
  });

  const onSubmit: SubmitHandler<InputsForm> = (data: InputsForm) => {
    const requestData = removeEmptyStringAttributes(handleFormatData(data));

    if (selectedId) {
      travelUpdateReq.mutate({ ...requestData, id: Number(selectedId) });
      addAndRemoveUsersOfTravel(Number(selectedId));
    } else {
      createTravelReq.mutate(requestData);
    }
  };

  const [travelsQuery] = useQueries([
    {
      queryKey: ["travels-selected", travelId],
      queryFn: () => {
        if (isNaN(travelId) || travelId === NEW_ITEM) {
          return;
        }
        const response = search({ id: travelId });
        return response;
      },
      retryOnMount: false,
      refetchOnWindowFocus: false,
      onSuccess: async (data: any) => {
        setTravels(data);
      },
    },
  ]);

  const [usersQuery] = useQueries([
    {
      queryKey: ["clients-list", travelId],
      queryFn: () => getClientsList(),
      keepPreviousData: true,
      onSuccess: (data: any) => {
        setListOfUsers(data);
      },
    },
    {
      queryKey: ["user-selected", travelId],
      queryFn: () => listUserDocs(travelId),
    },
  ]);

  useEffect(() => {
    handleSetFormatData();
    handleSetValues();
    setTimeout(() => {
      handleSetTravel();
    }, 1000);
  }, [travelId, travels]);

  useEffect(() => {
    handleSetValues();
  }, [getTravelData]);

  const handleSetValues = () => {
    if (
      getTravelData.title &&
      getTravelData.destination &&
      getTravelData.description
    ) {
      setValue("title", getTravelData.title);
      setValue("destination", getTravelData.destination);
      setValue("description", getTravelData.description);
    }
  };

  const handleSetTravel = () => {
    travels?.forEach((item: Travel) => {
      const startDate = formatedDateToShow(item.startdate);
      const endDate = formatedDateToShow(item.enddate);
      const startDay = startDate.slice(0, 2);
      const startMonth = startDate.slice(3, 5);
      const startYear = startDate.slice(6, 11);

      const endDay = endDate.slice(0, 2);
      const endMonth = endDate.slice(3, 5);
      const endYear = endDate.slice(6, 11);
      const formatBudget = numberToCurrency(Number(item.budget));

      setGetTravelData({
        ...item,
        startdate: startDate,
        enddate: endDate,
        startDay,
        startMonth,
        startYear,
        endDay,
        endMonth,
        endYear,
        budget: formatBudget,
      });
      setIsVisible(item.isvisible || false);
    });

    const usersTravel = new Set(
      travels?.map((tq) => tq.users_travels?.map((t) => t.user_id))[0]
    );
    const usersID = new Set(listOfUsers.map((item) => item.id));
    const checkedID = [...usersID].filter((element) =>
      usersTravel.has(element)
    );
    setSelectionModel(checkedID);
  };

  function addAndRemoveUsersOfTravel(id: number) {
    if (usersHasToUpdate) {
      const usersID = listOfUsers.map((item) => item.id);
      console.log("===> ", usersID);
      if (usersID.length > 0) {
        updateTravelUsers(id, { remove: [...new Set(usersID)] });
      }

      if (usersToAdd.length > 0) {
        updateTravelUsers(id, { add: [...new Set(usersToAdd)] });
      }
      toggleUsersHasToUpdate(false);
    }
  }

  const createTravelReq = useMutation({
    mutationFn: (travelData: Travel | TravelReceive) =>
      createTravel(removeEmptyStringAttributes(travelData)),
    onSuccess: (data) => {
      if (data.id) {
        setTravelId(data.id);
        setSelectedId(data.id);
        addAndRemoveUsersOfTravel(Number(data.id));
      }
      toast.success("Viagem cadastrada");
      queryClient.invalidateQueries("travels-list");
      queryClient.invalidateQueries(["travels-selected", travelId]);
    },
    onError: (error: any) => {
      toast.error(`teste ${error}`);
      toast.error(`Erro ao cadastrar a viagem ${error.message}`);
    },
  });

  const travelUpdateReq = useMutation({
    mutationFn: (travelData: Travel | TravelReceive) => update(travelData),
    onSuccess: (data) => {
      if (data.id) {
        setTravelId(data.id);
        setSelectedId(data.id);
      }
      toast.success("Viagem atualizada");
      queryClient.invalidateQueries("travels-list");
      queryClient.invalidateQueries(["travels-selected", travelId]);
    },
    onError: (error) => {
      toast.error(`Erro ao atualizar a viagem ${error}`);
    },
  });

  const handleSetFormatData = () => {
    const queryDataFormated = listOfUsers.map((item) => {
      const formatedPhone = numberToPhone(item.phone1);
      const emergenciFormatedPhone = numberToPhone(item.emergencyphone);
      const formatData: ReceiveUser = {
        ...item,
        phone1: formatedPhone,
        emergencyphone: emergenciFormatedPhone,
      };

      return { ...formatData };
    });
    setListOfUsers(queryDataFormated);
  };

  const handleFormatData = (data: InputsForm) => {
    const formatBudget = currencyToNumber(data.budget);
    const startDate = `${data.startYear}-${data.startMonth}-${data.startDay}`;
    const endDate = `${data.endYear}-${data.endMonth}-${data.endDay}`;
    let travel: TravelReceive = {
      ...data,
      destination: data.destination,
      title: data.title,
      budget: String(formatBudget),
      enddate: endDate,
      startdate: startDate,
      description: data.description,
      isvisible: isVisible,
    };

    if (
      data.countdownimagelinks === "" ||
      data.countdownimagelinks === null ||
      data.countdownimagelinks === undefined
    ) {
      delete travel.countdownimagelinks;
    }

    return travel;
  };

  return (
    <>
      <Container onSubmit={handleSubmit(onSubmit)}>
        <h1>Cadastrar viagem</h1>
        <FormContent>
          <ErrorAndInputContainer>
            <Input
              label="Título:*"
              register={{ ...register("title") }}
              color="white"
              placeholder="Título da Viagem"
              name="title"
              size="550px"
              defaultValue={getTravelData.title}
            />
            <Input
              register={{ ...register("destination") }}
              setValue={() =>
                travelId &&
                setValue("destination", getTravelData.destination || "")
              }
              defaultValue={getTravelData.destination}
              color="white"
              placeholder="Destino"
              label="Destino"
              name="destination"
              size="400px"
            />
          </ErrorAndInputContainer>

          <ErrorAndInputContainer>
            <DateContent>
              <InputLabel>Data de início*:</InputLabel>
              <DoubleItemsWrapper>
                <MaskedInput
                  register={{ ...register("startDay") }}
                  setValue={() =>
                    getTravelData.startDay &&
                    setValue("startDay", getTravelData.startDay)
                  }
                  defaultValue={getTravelData.startDay}
                  error={{
                    message: errors.startDay?.message,
                    showMessage: true,
                  }}
                  option="day"
                  name="startDay"
                  id="startDay"
                  color="white"
                  placeholder="DD"
                  size="50px"
                />

                <MaskedInput
                  register={{ ...register("startMonth") }}
                  defaultValue={getTravelData.startMonth}
                  setValue={() =>
                    getTravelData.startMonth &&
                    setValue("startMonth", getTravelData.startMonth)
                  }
                  error={{
                    message: errors.startMonth?.message,
                    showMessage: true,
                  }}
                  option="month"
                  id="startMonth"
                  name="startMonth"
                  color="white"
                  placeholder="MM"
                  size="50px"
                />

                <MaskedInput
                  register={{ ...register("startYear") }}
                  defaultValue={getTravelData.startYear}
                  setValue={() =>
                    getTravelData.startYear &&
                    setValue("startYear", getTravelData.startYear)
                  }
                  error={{
                    message: errors.startYear?.message,
                    showMessage: true,
                  }}
                  option="year"
                  id="startYear"
                  name="startYear"
                  color="white"
                  placeholder="AAAA"
                  size="120px"
                />
              </DoubleItemsWrapper>
            </DateContent>

            <DateContent>
              <InputLabel>Data de encerramento*:</InputLabel>
              <DoubleItemsWrapper>
                <MaskedInput
                  defaultValue={getTravelData.endDay}
                  register={{ ...register("endDay") }}
                  setValue={() =>
                    getTravelData.endDay &&
                    setValue("endDay", getTravelData.endDay)
                  }
                  error={{
                    message: errors.endDay?.message,
                    showMessage: true,
                  }}
                  option="day"
                  name="endDay"
                  id="endDay"
                  color="white"
                  placeholder="DD"
                  size="50px"
                />

                <MaskedInput
                  defaultValue={getTravelData.endMonth}
                  register={{ ...register("endMonth") }}
                  setValue={() =>
                    getTravelData.endMonth &&
                    setValue("endMonth", getTravelData.endMonth)
                  }
                  error={{
                    message: errors.endMonth?.message,
                    showMessage: true,
                  }}
                  option="month"
                  id="endMonth"
                  name="endMonth"
                  color="white"
                  placeholder="MM"
                  size="50px"
                />

                <MaskedInput
                  defaultValue={getTravelData.endYear}
                  register={{ ...register("endYear") }}
                  setValue={() =>
                    getTravelData.endYear &&
                    setValue("endYear", getTravelData.endYear)
                  }
                  error={{
                    message: errors.endYear?.message,
                    showMessage: true,
                  }}
                  option="year"
                  id="endYear"
                  name="endYear"
                  color="white"
                  placeholder="AAAA"
                  size="120px"
                />
              </DoubleItemsWrapper>
            </DateContent>
          </ErrorAndInputContainer>

          <ErrorAndInputContainer>
            <Input
              register={{ ...register("description") }}
              error={{
                message: errors.description?.message,
                showMessage: true,
              }}
              setValue={() =>
                travelId &&
                setValue("description", getTravelData.description || "")
              }
              defaultValue={getTravelData.description}
              color="white"
              placeholder="Descrição"
              label="Descrição"
              name="description"
              size="450px"
            />
            <SwitchWrapper>
              <InputLabel>Visibilidade: </InputLabel>
              <SwitchInput
                options={["Invisível", "Visível"]}
                checked={isVisible}
                handleChange={() => setIsVisible(!isVisible)}
              />
            </SwitchWrapper>
          </ErrorAndInputContainer>

          <BudgetWrapper>
            <InputLabel>Orçamento: </InputLabel>
            <MaskedInput
              id="budget"
              option="money"
              color="white"
              placeholder="custo da viagem"
              size="245px"
              error={{
                message: errors.budget?.message,
                showMessage: true,
              }}
              register={{ ...register("budget") }}
              setValue={() =>
                getTravelData.budget && setValue("budget", getTravelData.budget)
              }
              defaultValue={getTravelData.budget}
              name="budget"
            />
          </BudgetWrapper>

          <Divider
            style={{
              background: "#e3e3e3",
              height: "0.5px",
              width: "100%",
              opacity: 0.4,
            }}
          />
          {setValue && (
            <Images
              defaultValue={getTravelData.countdownimagelinks}
              setValue={setValue}
            />
          )}
          <Divider
            style={{
              margin: "10px 0",
              background: "#e3e3e3",
              height: "0.5px",
              width: "100%",
              opacity: 0.4,
            }}
          />

          {/* TODO: Quando o usuário pesquisa e a list é limpa, a seleção some */}
          {/* <Input
            onChange={(e: { target: { value: string } }) =>
              setSearchQuery(e.target.value)
            }
            color="white"
            placeholder="Pesquisar"
            label="Pesquisar"
            size="450px"
          /> */}
          <InputLabel>Participantes: </InputLabel>
        </FormContent>
        <TableContainer>
          <DataGrid
            style={{
              paddingTop: 5,
              paddingLeft: 5,
              borderRadius: 10,
              border: "none",
              height: "500px",
              outline: "none",
            }}
            loading={usersQuery.isLoading}
            checkboxSelection
            rows={listOfUsers}
            pageSize={limit}
            rowsPerPageOptions={[5]}
            columns={clientColumns}
            selectionModel={selectionModel}
            onSelectionModelChange={(newSelection) => {
              toggleUsersHasToUpdate(true);
              setUsersToAdd(newSelection);
              setSelectionModel(newSelection);
            }}
            components={{
              NoResultsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  Nada para ver aqui
                </Stack>
              ),
              NoRowsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  Nada para ver aqui
                </Stack>
              ),
            }}
          />
        </TableContainer>
        <SaveButtonWrapper>
          <Button buttonType="submit">Salvar</Button>
        </SaveButtonWrapper>

        {!isNaN(travelId) && travelId !== 0 && (
          <>
            <Checklist />
            <Tips />
            <ActivitiesForm travelId={travelId} />
          </>
        )}
      </Container>
    </>
  );
}
