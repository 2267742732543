import React, { useState } from "react";
import { DeleteSVG } from "assets/svg/components";
import { Button } from "components/Buttons";
import FormListItem from "./FormListItem";
import {
  Container,
  ButtonsWrapper,
  ListTitle,
  StyledInput,
  ButtonWrapper,
  ChecklistItemContainer,
} from "./style";
import TitleDescriptionList from "./TitleDescriptionItem";
import {
  DefaultProps,
  NormalItemProps,
  itemTitleDescriptionProps,
} from "./types";
import CheckboxStyled from "components/CheckboxStyled";
import { Pen } from "assets/svg/components/Pen";
import { IconButton } from "pages/Clients/List/Table/styles";

export default function ListItem(
  defaultFormProps: DefaultProps & itemTitleDescriptionProps
) {
  switch (defaultFormProps.type) {
    case "normal":
      return (
        <NormalList
          handleRemoveListItem={defaultFormProps.handleRemoveListItem}
          title={defaultFormProps.title}
          itemId={defaultFormProps.itemId}
          checkValue={defaultFormProps.checkValue}
          checkboxIsChange={defaultFormProps.checkboxIsChange}
          handleEdit={defaultFormProps.handleEdit}
          key={defaultFormProps.itemId}
          disabled={defaultFormProps.disabled}
        />
      );
    case "titleDescriptionList":
      return (
        <TitleDescriptionList
          description={defaultFormProps.description}
          handleRemoveListItem={defaultFormProps.handleRemoveListItem}
          itemId={defaultFormProps.itemId}
          title={defaultFormProps.title}
          handleEdit={defaultFormProps.handleEdit}
          isVisible={defaultFormProps.isVisible}
          toggleVisibility={defaultFormProps.toggleVisibility}
        />
      );
    case "formList":
      return (
        <FormListItem
          handleRemoveListItem={defaultFormProps.handleRemoveListItem}
          itemId={defaultFormProps.itemId}
          title={defaultFormProps.title}
          adress={defaultFormProps.adress}
          coast={defaultFormProps.coast}
          comment={defaultFormProps.comment}
          description={defaultFormProps.description}
          date={defaultFormProps.date}
          order={defaultFormProps.order}
          time={defaultFormProps.time}
          option={defaultFormProps.option}
        />
      );
    default:
      return (
        <NormalList
          handleRemoveListItem={defaultFormProps.handleRemoveListItem}
          title={defaultFormProps.title}
          itemId={defaultFormProps.itemId}
        />
      );
  }
}
type Props = {
  editValue: string;
  setEditValue: Function;
  handleEditItem: Function;
};
const EditInput = ({ editValue, setEditValue, handleEditItem }: Props) => {
  return (
    <>
      <StyledInput
        value={editValue}
        onChange={(e) => setEditValue(e.target.value)}
        type="text"
      />
      <ButtonWrapper onClick={() => handleEditItem()}>
        <Button>Salvar</Button>
      </ButtonWrapper>
    </>
  );
};
const NormalList = ({
  handleRemoveListItem,
  title,
  itemId,
  checkboxIsChange,
  checkValue,
  handleEdit,
  disabled,
}: NormalItemProps) => {
  const [isChecked, setIsChecked] = useState(checkValue ? checkValue : false);
  const [editInput, setEditInput] = useState(false);
  const [editValue, setEditValue] = useState(title);

  const handleCheckboxChange = () => {
    setIsChecked((old) => !old);
    if (checkboxIsChange) {
      checkboxIsChange(isChecked, itemId);
    }
  };

  const handleEditItem = () => {
    if (handleEdit) {
      handleEdit(itemId, editValue);
      setEditInput(false);
    }
    setIsChecked(false);
  };

  return (
    <Container>
      <CheckboxStyled
        checked={isChecked}
        handleCheckboxChange={handleCheckboxChange}
      />
      {editInput ? (
        <EditInput
          editValue={editValue}
          handleEditItem={handleEditItem}
          setEditValue={setEditValue}
        />
      ) : (
        <ChecklistItemContainer>
          <ListTitle done={isChecked}>{title}</ListTitle>
          {!disabled && (
            <ButtonsWrapper>
              <IconButton
                type="button"
                onClick={() => {
                  setEditInput((old) => !old);
                }}
              >
                <Pen />
                <p>Editar</p>
              </IconButton>
              <IconButton
                type="button"
                onClick={() => handleRemoveListItem(itemId)}
              >
                <DeleteSVG />
                <p>Deletar</p>
              </IconButton>
            </ButtonsWrapper>
          )}
        </ChecklistItemContainer>
      )}
    </Container>
  );
};
