import { User } from "types/data/User";
import { url, treatError, defaultHeaders } from "../../api";

export const update = async (user: Partial<User>): Promise<Boolean> => {
  const df = defaultHeaders();
  const headers = { ...df, mode: "no-cors" };
  return fetch(url(`users/edit/${user.id}`), {
    headers,
    method: "PATCH",
    body: JSON.stringify(user),
  }).then(async (r) => {
    if (r.ok) return r.ok;
    const errorMessage = await treatError(r);
    throw new Error(errorMessage);
  });
};

export default update;
